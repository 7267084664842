import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAjwvKNXGHc1SnZ6X41yK9xbxFpNTy8ets",
  authDomain: "coral-gardeners-playground.firebaseapp.com",
  projectId: "coral-gardeners-playground",
  storageBucket: "coral-gardeners-playground.appspot.com",
  messagingSenderId: "642512438673",
  appId: "1:642512438673:web:fe83eb6efca412a4b8c630"
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
