import React, { Suspense, useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Canvas, useLoader, useFrame } from '@react-three/fiber';
import { OrbitControls, Splat, Loader, useGLTF, Grid, useVideoTexture } from '@react-three/drei';
import { Leva, useControls, button } from 'leva'
import * as THREE from 'three';

function SkyBox() {
	const texture = useLoader(THREE.TextureLoader, "https://storage.googleapis.com/reefos-3d/images/skybox/underwater.png");

	return (
		<mesh>
			<sphereGeometry args={[500, 60, 40]} />
			<meshBasicMaterial map={texture} side={THREE.BackSide} />
		</mesh>
	);
}

function CameraModel({ onClick }) {
	const { scene } = useGLTF('/animations/camera/toy_cam.gltf');
	const [hovered, setHovered] = useState(false);

	const position = new THREE.Vector3(-0.01, 0.10, 1.07);
	const rotation = new THREE.Euler(3.14, -1.53, 3.14);

	useEffect(() => {
		scene.traverse((child) => {
			if (child.isMesh) {
				child.material.emissive = new THREE.Color(hovered ? 0x555555 : 0x000000);
				child.material.emissiveIntensity = hovered ? 0.5 : 0;
			}
		});
	}, [hovered, scene]);

	return (
		<primitive 
			object={scene} 
			scale={[0.1, 0.1, 0.1]}
			position={position}
			rotation={rotation}
			onClick={onClick}
			onPointerOver={(e) => {
				document.body.style.cursor = 'pointer';
				setHovered(true);
				e.stopPropagation();
			}}
			onPointerOut={(e) => {
				document.body.style.cursor = 'default';
				setHovered(false);
				e.stopPropagation();
			}}
		/>
	);
}

function FOVLines() {
	const cameraPosition = new THREE.Vector3(-0.01, 0.48, 1);
	const blankRectanglePosition = new THREE.Vector3(0, 0.5, 0.2);
	const rectangleWidth = 1.77;  // 16:9 aspect ratio
	const rectangleHeight = 1;

	const lines = useMemo(() => {
		const halfWidth = rectangleWidth / 2;
		const halfHeight = rectangleHeight / 2;

		const corners = [
			new THREE.Vector3(blankRectanglePosition.x - halfWidth, blankRectanglePosition.y + halfHeight, blankRectanglePosition.z),
			new THREE.Vector3(blankRectanglePosition.x + halfWidth, blankRectanglePosition.y + halfHeight, blankRectanglePosition.z),
			new THREE.Vector3(blankRectanglePosition.x + halfWidth, blankRectanglePosition.y - halfHeight, blankRectanglePosition.z),
			new THREE.Vector3(blankRectanglePosition.x - halfWidth, blankRectanglePosition.y - halfHeight, blankRectanglePosition.z),
		];

		return corners.map((corner, index) => {
			const points = [cameraPosition, corner];
			const geometry = new THREE.BufferGeometry().setFromPoints(points);
			return (
				<line key={`fov-line-${index}`} geometry={geometry}>
					<lineBasicMaterial color="white" linewidth={1} />
				</line>
			);
		});
	}, []);

	return (
		<>
			{lines}
			<mesh position={blankRectanglePosition}>
				<planeGeometry args={[rectangleWidth, rectangleHeight]} />
				<meshBasicMaterial color="white" opacity={0.2} transparent={true} />
			</mesh>
		</>
	);
}

function Fish() {
	const gltf = useGLTF('/animations/fish/scene.gltf');
	const mixer = useRef();
	const fishRef = useRef();
	const clockRef = useRef(new THREE.Clock());
	const targetPositionRef = useRef(new THREE.Vector3());

	useFrame((state, delta) => {
		mixer.current?.update(delta);

		if (fishRef.current) {
			const fish = fishRef.current;
			const time = clockRef.current.getElapsedTime();

			// Move towards target position
			fish.position.lerp(targetPositionRef.current, 0.002);

			// Rotate to face movement direction
			const direction = new THREE.Vector3().subVectors(targetPositionRef.current, fish.position);
			if (direction.length() > 0.1) {
				const targetRotation = Math.atan2(direction.x, direction.z);
				fish.rotation.y = THREE.MathUtils.lerp(fish.rotation.y, targetRotation, 0.1);
			}

			// Set new target position when close to current target
			if (fish.position.distanceTo(targetPositionRef.current) < 0.5) {
				const radius = 2; // Maximum distance from center
				const randomAngle = Math.random() * Math.PI * 2;
				const randomRadius = Math.random() * radius;
				targetPositionRef.current.set(
					Math.cos(randomAngle) * randomRadius,
					(Math.random() - 0.2) * 1, 
					Math.sin(randomAngle) * randomRadius
				);
			}

			// Add subtle wave motion
			fish.position.y += Math.sin(time * 2) * 0.0005;
		}
	});

	useEffect(() => {
		if (gltf.animations.length) {
			mixer.current = new THREE.AnimationMixer(gltf.scene);
			const action = mixer.current.clipAction(gltf.animations[0]);
			action.play();
		}
	}, [gltf]);

	return (
		<primitive 
			ref={fishRef}
			object={gltf.scene} 
			scale={[0.001, 0.001, 0.001]}
			position={[0, 1, 0]}
		/>
	);
}

function Logo() {
	return (
		<div style={{
			position: 'absolute',
			bottom: '15px',
			left: '15px',
			zIndex: 1000
		}}>
			<img src="/cg.png" alt="Logo" style={{ width: '30px' }} />
		</div>
	);
}

function Shark() {
	const gltf = useGLTF('/animations/shark/scene.gltf');
	const mixer = useRef();
	const sharkRef = useRef();
	const { videoRef } = useVideoContext();

	useEffect(() => {
		if (gltf.animations.length) {
			mixer.current = new THREE.AnimationMixer(gltf.scene);
			const action = mixer.current.clipAction(gltf.animations[0]);
			action.setDuration(action.getClip().duration / 1.3);
			action.play();

			// Set all materials to transparent
			gltf.scene.traverse((child) => {
				if (child.isMesh) {
					child.material.transparent = true;
				}
			});
		}
	}, [gltf]);

	useFrame((state, delta) => {
		mixer.current?.update(delta * 1.3);

		if (sharkRef.current && videoRef.current) {
			const progress = videoRef.current.currentTime / videoRef.current.duration;
			
			// Move shark from one corner to the opposite
			sharkRef.current.position.x = THREE.MathUtils.lerp(-1.5, 1.5, progress);
			sharkRef.current.position.z = THREE.MathUtils.lerp(-1, 1, progress);
			
			// Add subtle arc movement
			sharkRef.current.position.y = Math.sin(progress * Math.PI) * .05 + .7;
			
			// Rotate shark to face movement direction
			sharkRef.current.rotation.y = Math.atan2(1, 1) + Math.PI;

			
			const fadeInDuration = 0.2; 
			const fadeOutStart = 0.8; 
			let opacity;
			if (progress < fadeInDuration) {
				opacity = progress / fadeInDuration;
			} else if (progress > fadeOutStart) {
				opacity = 1 - (progress - fadeOutStart) / (1 - fadeOutStart);
			} else {
				opacity = 1;
			}

			sharkRef.current.traverse((child) => {
				if (child.isMesh) {
					child.material.opacity = opacity;
				}
			});
		}
	});

	return (
		<primitive 
			ref={sharkRef}
			object={gltf.scene} 
			scale={[0.008, 0.008, 0.008]}
			position={[0, 0, 0]}
		/>
	);
}

// Create a context to share the video reference
const VideoContext = React.createContext();

function VideoContextProvider({ children }) {
	const videoRef = useRef();
	return (
		<VideoContext.Provider value={{ videoRef }}>
			{children}
		</VideoContext.Provider>
	);
}

function useVideoContext() {
	return useContext(VideoContext);
}

function VideoPlane() {
	const videoTexture = useVideoTexture("/shark.mp4", {
		unsuspend: 'canplay',
		muted: true,
		loop: true,
		crossOrigin: 'anonymous',
		playsInline: true,
	});
	const [videoPlaying, setVideoPlaying] = useState(false);
	const { videoRef } = useVideoContext();

	const handleClick = (event) => {
		event.stopPropagation();
		setVideoPlaying(!videoPlaying);
		if (videoPlaying) {
			videoTexture.image.pause();
		} else {
			videoTexture.image.play().catch(e => console.error("Error playing video:", e));
		}
	};

	useFrame(() => {
		if (videoPlaying) {
			// console.log("Video time:", videoRef.current.currentTime.toFixed(2));
		}
	});

	useEffect(() => {
		videoRef.current = videoTexture.image;
		videoRef.current.playsInline = true;
		videoRef.current.muted = true;
		videoRef.current.setAttribute('playsinline', '');
		videoRef.current.setAttribute('webkit-playsinline', '');
	}, [videoTexture]);

	return (
		<group position={[-0.01, 0.88, 1.1]}> 
			<mesh onClick={handleClick}>
				<planeGeometry args={[0.8, 0.45]} /> {/* Slightly smaller size, maintaining 16:9 aspect ratio */}
				<meshBasicMaterial map={videoTexture} toneMapped={false} />
			</mesh>
		</group>
	);
}

function App() {
	const modelUrls = [
		{ name: 'Bommie 29', url: 'https://storage.googleapis.com/reefos-3d/splats/bommie_29_2.splat' },
		{ name: 'Bommie 27', url: 'https://storage.googleapis.com/reefos-3d/splats/bommie_27.splat' },
		{ name: 'Bommie 2', url: 'https://storage.googleapis.com/reefos-3d/splats/bommie_2.splat' },
		{ name: 'Bommie 3', url: 'https://storage.googleapis.com/reefos-3d/splats/bommie_3.splat' },
		{ name: 'Bommie 4', url: 'https://storage.googleapis.com/reefos-3d/splats/bommie_4.splat' },
		{ name: 'Bommie 5', url: 'https://storage.googleapis.com/reefos-3d/splats/bommie_5.splat' },
		{ name: 'Bommie 6', url: 'https://storage.googleapis.com/reefos-3d/splats/bommie_6.splat' },
		{ name: 'Tikehau Coral', url: 'https://storage.googleapis.com/reefos-3d/splats/tikehau_coral.splat' },
		{ name: 'Tikehau Reef', url: 'https://storage.googleapis.com/reefos-3d/splats/tikehau_reef.splat' },
		{ name: 'Tikehau Canyon', url: 'https://storage.googleapis.com/reefos-3d/splats/tikehau_canyon.splat' },
	];

	const { model } = useControls({
		model: {
			options: Object.fromEntries(modelUrls.map(model => [model.name, model.url])),
			value: modelUrls[0].url,
		},
	});

	const { underwater, grid, fish, camera } = useControls(
		{
			underwater: true,
			fish: true,
			grid: false,
			camera: {
				value: false,
				disabled: model !== modelUrls[0].url,  // Disable if not Bommie 29
			},
			'Visit Coral Gardeners': button(() => {
				window.open('https://coralgardeners.org', '_blank', 'noopener,noreferrer');
			}),
		},
		[model]  // Re-create controls when model changes
	);

	const levaTheme = { colors: { accent1: '#27bdf4', accent2: '#27bdf4', accent3: '#27bdf4' } }

	const [showSkybox, setShowSkybox] = useState(underwater);
	const [isCameraModelActive, setIsCameraModelActive] = useState(false);

	useEffect(() => {
		setShowSkybox(underwater);
	}, [underwater]);

	const handleCameraClick = (event) => {
		event.stopPropagation();
		setIsCameraModelActive(!isCameraModelActive);
	};

	const CustomTitleBar = () => (
		<div className="leva-custom-title-bar">
			<img 
				src="/cg.png" 
				alt="Logo" 
				className="leva-logo"
			/>
			<span>3D Reef Splat</span>
		</div>
	);

	return (
		<div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
			<Canvas 
				camera={{ position: [1.55, 1.84, 2.73], rotation: [-0.58, 0.46, 0.28], fov: 50 }}
				style={{ background: 'black' }}
			>
				<ambientLight intensity={1.5} />
				<pointLight position={[5, 5, 5]} intensity={1.5} />
				<directionalLight position={[0, 5, 5]} intensity={0.8} />

				<OrbitControls /> {/* Remove the 'enabled' prop */}
				{ showSkybox && <SkyBox />}
				<Suspense fallback={null}>
					{model && <Splat alphaTest={0.01} src={model} />}
					{fish && <Fish />}
					{camera && <CameraModel onClick={handleCameraClick} />}
					<VideoContextProvider>
						{isCameraModelActive && camera && (
							<>
								<Shark />
								<VideoPlane />
							</>
						)}
					</VideoContextProvider>
					{grid && (
						<Grid args={[10, 10]} cellSize={0.5} cellThickness={1} cellColor="#6f6f6f"
							sectionSize={2} sectionThickness={1} sectionColor="#27bdf4"
							fadeDistance={10} fadeStrength={1} followCamera={false} infiniteGrid={true} />
					)}
				</Suspense>
			</Canvas>
			<Loader 
				containerStyles={{ background: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(5px)' }}
				innerStyles={{ backgroundColor: 'transparent' }}
				barStyles={{ backgroundColor: 'white' }}
				dataStyles={{ color: 'white' }}
			/>
			<Leva theme={levaTheme} collapsed={true} titleBar={{title: <CustomTitleBar />, filter: false}} />
			<Logo />
			<style>{`
				.leva-custom-title-bar {
					display: flex;
					align-items: center;
					gap: 8px;
					color: #6c6c6c;
					transition: color 0.3s ease;
				}
				.leva-custom-title-bar:hover {
					color: white;
				}
				.leva-logo {
					width: 12px;
					height: 12px;
					filter: brightness(0) saturate(100%) invert(42%) sepia(0%) saturate(0%) hue-rotate(147deg) brightness(98%) contrast(92%);
					transition: filter 0.3s ease;
				}
				.leva-custom-title-bar:hover .leva-logo {
					filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(147deg) brightness(102%) contrast(102%);
					transition: filter 0.3s ease;
				}
			`}</style>
		</div>
	);
}

export default App;